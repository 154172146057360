import { WppTextareaInput, WppButton, WppDivider, WppInput } from '@wppopen/components-library-react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { AddAgencyParams } from 'api/fetchers/agencies/addAgency'
import { useAddAgency } from 'api/mutations/agencies/useAddAgency'
import { useUpdateAgency } from 'api/mutations/agencies/useUpdateAgency'
// import { useUploadGcpFile } from 'api/mutations/files/useUploadGcpFile'
import { useFileUploadUrls } from 'api/queries/files/useFileUploadUrls'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { queryClient } from 'app/Root'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { Agency, AgencyAddedResponse } from 'types/agencies/agencies'

// import AgencyFileList from './agencyFileList/AgencyFileList'
import style from './editaddagency.module.scss'

export interface EditAddAgencyProps {
  agency?: Agency
  handleCancel?: () => void
  handleSave?: (name?: string, description?: string) => void
}

export const EditAddAgency = ({ agency, handleCancel, handleSave }: EditAddAgencyProps) => {
  const [descriptionValue, setDescriptionValue] = useState<string>(agency?.description || '')
  const [nameValue, setNameValue] = useState<string>(agency?.name || '')
  const [wppFiles, setWppFiles] = useState<File[] | null>(null)
  const textAreaRef = useRef<HTMLWppTextareaInputElement>(null)
  const [disableSave, setDisableSave] = useState(!agency?.name)
  const [task, setTask] = useState<AgencyAddedResponse | null>(null)
  const agencyId = agency?.id || ''
  // const tooltipText = 'Knowledge base will be changed according to the import selection.'

  const maxLength = 2000

  const toast = useToast()
  // const { mutateAsync: uploadFile } = useUploadGcpFile()
  const { mutateAsync: updateAgency } = useUpdateAgency()
  const [{ data: fileUploadUrls }, generateFileUploadUrls] = useFileUploadUrls()
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: task?.id || '' },
    enabled: !!task?.id,
    refetchInterval: wppFiles?.length ? 5000 : 2000,
  })

  const { mutateAsync: addAgency } = useAddAgency({
    onError: error => {
      toast.showToast({
        message: error.message,
        type: 'error',
      })
    },
  })

  // const handleFileUploadChange = (event: CustomEvent) => setWppFiles(event.detail.value)

  const handleClearAll = () => {
    setNameValue('')
    setDescriptionValue('')
    setWppFiles(null)
  }

  const onHandleCancel = () => {
    handleClearAll()
    handleCancel && handleCancel()
  }

  useEffect(() => {
    setDisableSave(!nameValue)
  }, [nameValue])

  const prepareRequest = useCallback(
    (file_keys: string[]): AddAgencyParams => {
      let request: AddAgencyParams = { params: { name: nameValue, description: descriptionValue, file_keys } }

      if (agencyId) {
        request.agencyId = agencyId
      }
      return request
    },
    [descriptionValue, nameValue, agencyId],
  )

  const onHandleSave = useCallback(async () => {
    setDisableSave(true)

    if (!nameValue) {
      return toast.showToast({
        message: 'Please enter a name for the agency',
        type: 'error',
      })
    }

    if (wppFiles) {
      generateFileUploadUrls({ file_names: wppFiles.map(file => file.name), file_type: 'agency_file' })
    } else {
      handleSaveAgency()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateFileUploadUrls, nameValue, toast, wppFiles])

  useEffect(() => {
    if (fileUploadUrls && fileUploadUrls.length) {
      uploadFiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadUrls])

  useEffect(() => {
    const { status, currentStep, error } = taskStatus || {}

    if (status === 'Parsing files for processing...' && currentStep === 1) {
      toast.showToast({
        message: 'Parsing files for processing...',
        type: 'information',
        duration: 14000,
      })
    }
    if (status === 'Processing...' && currentStep === 1) {
      toast.showToast({
        message: 'Processing...',
        type: 'information',
        duration: 14000,
      })
    }
    if (status === 'failed') {
      toast.showToast({
        message: 'Something went wrong while adding the agency. Please try again.',
        type: 'error',
      })
      setTask(null)
    }
    if (status?.includes('Error') || error) {
      toast.showToast({
        message: status,
        type: 'error',
      })
      setTask(null)
      return
    }

    if (status === 'completed') {
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCIES] }).then(() => {
        toast.showToast({
          message: `Agency  ${agency ? 'updated' : 'added'} successfully`,
          type: 'success',
        })
      })
      setTask(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency, taskStatus])

  const uploadFiles = async () => {
    if (fileUploadUrls && fileUploadUrls.length && wppFiles) {
      fileUploadUrls.forEach(async fileUrl => {
        try {
          const formDataAPI = new FormData()
          const files = wppFiles.map(file => new Blob([file], { type: 'application/pdf' }))
          const file = files[0]
          formDataAPI.append('file', file, fileUrl.name)
          // await uploadFile({ uploadUrl: fileUrl.signed_url, formData: formDataAPI })
          await fetch(fileUrl.signed_url, {
            headers: {
              'Content-Type': 'application/pdf',
              'x-goog-content-length-range': '1,1000000000',
            },
            body: formDataAPI,
            method: 'put',
          })
        } catch (e) {
          console.error('Error editing project data', e)
        }
      })
      handleSaveAgency()
    }
  }

  const handleSaveAgency = async () => {
    const request = prepareRequest(fileUploadUrls ? fileUploadUrls.map(file => file.key) : [])

    try {
      if (agencyId) {
        const res = await updateAgency(request)
        setTask(res.data)
      } else {
        const res = await addAgency(request)
        setTask(res.data)
      }

      handleSave && handleSave(nameValue, descriptionValue)
      setDisableSave(false)
      !agencyId && handleClearAll()
    } catch (e) {
      toast.showToast({
        message: 'Something went wrong while adding the agency. Please try again.',
        type: 'error',
      })
    }
    queryClient.resetQueries({ queryKey: [ApiQueryKeys.FILE_UPLOAD_URLS] })
  }

  const handleCheckCharsOnPaste = (e: React.ClipboardEvent) => {
    const { clipboardData } = e
    const text = clipboardData.getData('text')
    if (text.length + descriptionValue.length > maxLength) {
      e.preventDefault()
      toast.showToast({
        message: `You can't paste more than ${maxLength} characters`,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    if (textAreaRef.current) {
      const shadowRoot = textAreaRef.current.shadowRoot
      const textArea = shadowRoot?.querySelector('textarea')
      textArea?.setAttribute('maxlength', maxLength.toString())
    }
  }, [descriptionValue])

  return (
    <>
      <div className="py-4">
        <WppInput
          name={nameValue}
          placeholder="Add an agency name"
          required
          labelConfig={{
            text: 'Agency Name',
          }}
          value={nameValue}
          onWppChange={e => setNameValue(e?.detail?.value || '')}
        />
      </div>
      <div className="py-4">
        <WppTextareaInput
          ref={textAreaRef}
          labelConfig={{
            text: 'Description',
          }}
          onWppChange={e => setDescriptionValue(e?.detail?.value || '')}
          value={descriptionValue}
          charactersLimit={maxLength}
          maxMessageLength={maxLength}
          warningThreshold={maxLength - 100}
          content={descriptionValue}
          onPaste={handleCheckCharsOnPaste}
          className={style.textArea}
        />
      </div>
      {/* <div className="py-4">
        <div className="mb-2 flex flex-row items-center gap-1">
          <WppTypography type="s-strong" className={style.fileUploadtype}>
            Knowledge Base Upload
          </WppTypography>
          <WppTypography type="s-body">(Optional)</WppTypography>
          <WppTooltip text={tooltipText} className={style.filesInfoTooltip}>
            <WppIconInfo />
          </WppTooltip>
        </div>
        <WppFileUpload
          name="description"
          acceptConfig={{ 'application/pdf': ['.pdf'] }}
          onWppChange={handleFileUploadChange}
          format="arrayBuffer"
        />
      </div> */}

      <WppDivider />
      <div className="flex ">
        <WppButton onClick={onHandleCancel} className="ml-auto mr-4" variant="secondary">
          Cancel
        </WppButton>
        <WppButton
          onClick={() => {
            onHandleSave()
          }}
          className="mr-4"
          disabled={disableSave}
        >
          Save
        </WppButton>
      </div>
    </>
  )
}
